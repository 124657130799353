// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["k3oF1uv1g"];

const variantClassNames = {k3oF1uv1g: "framer-v-p3ppkc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "k3oF1uv1g", image: gAJgzKMY6 = {src: new URL("assets/eJP5iXU6iGgRCbO0tZRWpUvqGE.png", import.meta.url).href, srcSet: `${new URL("assets/512/eJP5iXU6iGgRCbO0tZRWpUvqGE.png", import.meta.url).href} 512w, ${new URL("assets/eJP5iXU6iGgRCbO0tZRWpUvqGE.png", import.meta.url).href} 987w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "k3oF1uv1g", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-dHPKf", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-p3ppkc", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"k3oF1uv1g"} ref={ref} style={{...style}} transition={transition}><Image background={{alt: "", fit: "fit", intrinsicHeight: 747, intrinsicWidth: 987, pixelHeight: 747, pixelWidth: 987, ...toResponsiveImage_194x2gw(gAJgzKMY6)}} className={"framer-oamzo1"} data-framer-name={"image 20"} layoutDependency={layoutDependency} layoutId={"LMeO_UYuH"} style={{filter: "grayscale(1) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))", WebkitFilter: "grayscale(1) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))"}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-dHPKf [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-dHPKf * { box-sizing: border-box; }", ".framer-dHPKf .framer-woq0z3 { display: block; }", ".framer-dHPKf .framer-p3ppkc { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 30px 30px 30px 30px; position: relative; width: 1200px; }", ".framer-dHPKf .framer-oamzo1 { aspect-ratio: 1.3103448275862069 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 870px); overflow: hidden; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-dHPKf .framer-p3ppkc { gap: 0px; } .framer-dHPKf .framer-p3ppkc > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-dHPKf .framer-p3ppkc > :first-child { margin-left: 0px; } .framer-dHPKf .framer-p3ppkc > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 930
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"gAJgzKMY6":"image"}
 */
const FramerJWFnJe0vW: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerJWFnJe0vW;

FramerJWFnJe0vW.displayName = "Spare time image 1";

FramerJWFnJe0vW.defaultProps = {height: 930, width: 1200};

addPropertyControls(FramerJWFnJe0vW, {gAJgzKMY6: {__defaultAssetReference: "data:framer/asset-reference,eJP5iXU6iGgRCbO0tZRWpUvqGE.png?originalFilename=image.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerJWFnJe0vW, [])